import * as React from "react";

import Layout from "../components/layout";

import logo from "../images/logo.png";

import LSlogo from "../images/LSLOGO.png";

import { useStaticQuery, graphql } from "gatsby";

import Img from "gatsby-image";

export default function About() {
  const data = useStaticQuery(graphql`
    {
      Lewis: file(relativePath: { eq: "Lewis.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beach: file(relativePath: { eq: "beach.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            base64
            srcWebp
            srcSetWebp
            originalImg
            originalName
            presentationHeight
            presentationWidth
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
      sunset: file(relativePath: { eq: "sunset.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            base64
            srcWebp
            srcSetWebp
            originalImg
            originalName
            presentationHeight
            presentationWidth
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
      Lewis2: file(relativePath: { eq: "Lewis-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            base64
            srcWebp
            srcSetWebp
            originalImg
            originalName
            presentationHeight
            presentationWidth
            sizes
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  `);

  const beach = data["beach"].childImageSharp.fluid;

  const Lewis = data["Lewis"].childImageSharp.fluid;
  const sunset = data["sunset"].childImageSharp.fluid;
  const Lewis2 = data["Lewis2"].childImageSharp.fluid;

  return (
    <Layout>
      <h2 className="section-header text-center">About</h2>
      <div className="relative ">
        <div className="absolute inset-0">
          <Img
            fluid={beach}
            alt="landing"
            imgStyle={{
              zIndex: -1,
              objectPosition: "72% 0",
              filter: "brightness(0.7)",
            }}
            className="h-full"
          />
        </div>
        <div className=" sm:p-20 relative flex flex-col">
          <img
            src={logo}
            alt={logo}
            className="  w-64 py-10 sm:p-0 object-contain m-auto"
          />
          <div className="text-xl text-white w-5/6  m-auto text-center text-shadow">
            <p className="max-w-xl m-auto">
              The world has so many powerful untold stories and we are a company
              that want to be a part of changing that. We want to be a part of
              you telling your story.
            </p>

            <p className="max-w-xl m-auto pt-10">
              Visual Insight is going to hopefully be a platform where I can
              share visual, audio and spiritual insights into the lives of the
              people who are on the journey of life.
            </p>
            <p className="max-w-xl m-auto pt-10">
              It’s a platform where I hope to tell meaningful stories of the
              people I interact with and their journeys.
            </p>
            <p className="max-w-xl m-auto pt-10">
              It’s also a platform where we can help brands and personal brands
              tell their stories in a way which has a more positive impact on
              the world. The stories that make a difference.
            </p>
            <p className="max-w-xl m-auto pt-10">
              Hopefully this platform can open up room for conversations in our
              personal interactions with people.
            </p>

            <p className=" m-auto mt-16 italic">
              we provide storytelling, documentary, promotional, event, sport,
              drone, product and other content
            </p>
          </div>
        </div>
      </div>
      <h2 className="section-header">Who is Lewis Seymour?</h2>

      <div className="flex flex-col sm:flex-row">
        <Img fluid={sunset} alt="sunset" className="w-full" />
        <Img
          fluid={Lewis}
          alt="Lewis"
          className="w-full"
          imgStyle={{ objectPosition: "65% 0" }}
        />
        <Img fluid={Lewis2} alt="Lewis-2" className="w-full" />
      </div>

      <h3 className="text-3xl text-center py-10">
        storyteller <i className="hidden sm:inline">||</i> Photographer{" "}
        <i className="hidden sm:inline">||</i> filmmaker
      </h3>

      <img src={LSlogo} alt={LSlogo} className="w-72 m-auto" />

      <div className="text-white text-center text-lg px-5 sm:px-10">
        <p className="pb-10">
          Hi, my name is Lewis, I'm a young filmmaker, studying engineering,
          with a passion to make a difference in the world. I want to tell
          stories, stories that make a difference, stories that change lives!
        </p>
        <p className="pb-10">
          "The world has so many powerful untold stories and I want to be a part
          of changing that."
        </p>
        <p className=" font-bold italic">How did I start?</p>
        <p className="pb-10">
          I wish I could explain the importance of the whole story about how I
          got into creating content, but the story is too powerful to mention in
          a few short sentences. I can however try to give a short run down. I
          have always been a keen and good sportsman, but I have had a tough
          journey with injuries through my high school career. After being
          sidelined for two rugby seasons because of a collarbone injury and an
          injury in which I nearly lost my life (more info on a blog post)  I
          was forced to find another way of contributing towards my team because
          I couldn't just stand and watch them play. So I picked up a camera and
          since then I haven't looked back.
        </p>
        <p className="pb-10">
          After many hours of practicing and teaching myself how to record and
          edit videos on youtube I soon started falling in love with the art.
          With little talent at first but a lot of passion I was able to somehow
          find myself on a path of resilience by breaking through the adversity
          barriers I had to overcome. It wasn't long before my school started
          involving me in creating content for them. By the grace of God I was
          able to play sport again and was now able to create content that would
          inspire a new group of people to help me on my journey of telling
          stories.
        </p>
        <p className=" font-bold italic">Why I Create Content?</p>
        <p className="pb-10">
          Entering the creative field completely changed the way that my head
          works. Improving my creativity allowed me to exponentially improve all
          aspects of my life and it opened a lot of doors for me. Creating
          content went from something I never understood to something that I
          couldn't live without.
        </p>
        <p className="pb-10">
          The art of storytelling is something I have fallen in love with and I
          want to share my passion for what I do and what I can create with the
          people who watch my content and who want me to help them build their
          brand.
        </p>
        <p className=" font-bold italic">My Goals?</p>
        <p className="pb-10">
          My goals are to keep creating content. I have a lot of personal ideas,
          but I also want to be a part of you telling your stories, I want to be
          a part of you changing the world through your message and vision and I
          want to be there to help capture your favourite memories so that you
          can cherish them forever.
        </p>
        <p className="pb-10">
          If you've got a story worth telling pop me a mail and lets talk.
        </p>
        <p className=" font-bold italic">Some Brands I've Worked With:</p>
        <p className="pb-10">
          Many of the brand projects I've worked on were for social impact
          community projects. I used the skills that I learnt from experiencial
          learning on these projects to be able to create content that even the
          biggest of brands such as Standardbank, MTN, and Allianz used as
          promotional content and put it up in their offices. As well as getting
          the opportunity to work with T+W on a project they were doing for
          Supersport on the springboks #strongertogether campaign to create the
          rugby world cup announcement video.
        </p>
      </div>
    </Layout>
  );
}
